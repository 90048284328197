$(document).ready(function () {

  var $target = $('.messages-unread');
  if ($target.length) {
    var url = '/api/messages.json';
    $.get(url, function (data) {
      var count = data.unread.count;
      if (!count) {
        return;
      }
      var $html = $(' <span class="pull-right-container"> ' +
        '<small class="label pull-right bg-yellow">' + count + '</small></span>');
      var $a = $target.find('a');
      $a.append($html);
    });
  }

});

$(document).ready(function () {

  var $target = $('.evaluations-pending');
  if ($target.length) {
    var url = '/teacher/api/evaluations.json';
    $.get(url, function (data) {
      var count = data.pending.count;
      if (!count) {
        return;
      }
      var $html = $(' <span class="pull-right-container"> ' +
        '<small class="label pull-right bg-yellow">' + count + '</small></span>');
      var $a = $target.find('a');
      $a.append($html);
    });
  }

});