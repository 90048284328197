$(document).ready(function () {

  $('.copy').click(function (e) {
    e.preventDefault();

    var $that = $(this);
    var $target = $($that.data('target'));

    $that.tooltip({
      title: 'copied!',
      trigger: 'manual'
    });
    $that.tooltip("show");
    setTimeout(function () {
      $that.tooltip("destroy");
    }, 2000);

    $target.select()
    document.execCommand("copy");
  });
});