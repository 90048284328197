$(document).ready(function () {


  $(".birthdaypicker").datetimepicker(
    {
      enabledHours: false,
      format: 'DD/MM/YYYY',
      viewMode: 'years'
    }
  );
  $(".datepicker").datetimepicker(
    {
      enabledHours: false,
      format: 'DD/MM/YYYY'
    }
  );

  $(".datetimepicker").datetimepicker(
    {
      format: 'DD/MM/YYYY HH:mm',
    }
  );

  $(".timepicker").datetimepicker(
    {
      enabledDates: false,
      format: 'HH:mm'
    }
  );

  $('.btn-punctuation a.btn').click(function (e) {
    e.preventDefault();
    var value = $(this).data('value');
    var target = $(this).data('target');
    var $parent = $(this).parent();
    var $brother = $parent.find('a.btn');
    var $target = $('#' + target);
    $brother.removeClass('active');
    $(this).addClass('active');
    $target.val(value);
  });
  $(".knob").knob({
    'min': 0,
    'max': 10,
  });
  $('.box-footer .select2.select2-container.select2-container--default').removeAttr('style');
});


