var Calendar = {

  init: function () {
    this.highlightToday();
    var width = $('.table-calendar').width();
    $('.day').width(width / 7);
  },
  lessons: function (url, link) {
    return $.get(url, function (data) {
      if (!data.length) {
        return;
      }
      data.forEach(function (item) {

        var d = new Date();

        var day = ("0" + d.getDate()).slice(-2);
        var month = ("0" + (d.getMonth() + 1)).slice(-2);

        var date = moment(item.date, moment.ISO_8601);
        var today = false;
        if (date.format('DD/MM/YYYY') == day + '/' + month + '/' + d.getYear()) {
          today = true;
        }

        var current_link = Routing.generate('_generic.lesson.view', {id: item.id});
        var label = 'default';
        var icon_type = 'question';
        var icon_count = 'user';


        if (link != undefined) {
          current_link = link.replace('id', item.id);
        }
        if (item.state == 'PENDING') {
          label = 'warning';
        }
        if (item.state == 'FINALIZED') {
          label = 'success';
        }
        if (item.type == 'FACE_TO_FACE') {
          icon_type = 'map-marker'
        }
        if (item.type == 'TELEPHONE') {
          icon_type = 'skype'
        }
        if (item.type == 'VIRTUAL_CLASS_ROOM') {
          icon_type = 'desktop'
        }
        if (item.type == 'WORKSHOP') {
          icon_type = 'rocket'
        }
        if (item.group.count == 1) {
          icon_count = 'user'
        }
        if (item.group.count > 1) {
          icon_count = 'users'
        }
        var $html = $('<div class="label label-' + label + ' calendar-lesson" ' +
          ' data-id="' + item.id + '" title="Group: ' + item.group.name + ', class: ' + item.type + '"' +
          '><a href="' + current_link + '">' + date.format('HH:mm') + ' - ' +
          item.group.name + ' <i class="fa fa-' + icon_count + '" aria-hidden="true"></i> <i class="fa fa-' +
          icon_type + '" aria-hidden="true"></i></a> </div>'
        );
        Calendar.append(item, date, $html, today);
      });
    });
  },
  events: function (url) {
    return $.get(url, function (data) {
      if (!data.length) {
        return;
      }
      data.forEach(function (item) {
        var date = moment(item.date, moment.ISO_8601);
        var $html = $('<span class="label label-info calendar-event" data-toggle="tooltip" title="' +
          item.name + ' ' + item.description + '">' + Calendar.truncate(item.name) + '</span>');
        Calendar.prepend(item, date, $html);
      });
    });
  },
  prepend: function (item, date, $html) {
    var id = date.format('#YY-MM-DD');
    var $target = $(id);
    $target.prepend($html);
    $html.tooltip();
  },
  append: function (item, date, $html, today) {
    var $target = Calendar.getTarget(date);
    if (today == true) {
      $target.addClass('grey');
    }
    $target.append($html);
    $html.tooltip();
  },
  droppable: function (url_move, url_delete) {
    $('.calendar-lesson').draggable({
      revert: "invalid",
    });
    $('.day').droppable({
      drop: function (event, ui) {
        var $that = $(this);
        var $item = ui.draggable;
        var date = $(this).attr('id');
        var id = $item.data('id');
        var current_url = url_move.replace('id', id);

        $item.appendTo($that).css({'top': 0, 'left': 0});
        current_url = current_url.replace('date', date);
        $.post(current_url);
      }
    });
    var $delete = $('<i class="fa fa-times" aria-hidden="true" data-url="' + url_delete + '"> </i>');
    $delete.click(function () {
      var $that = $(this);
      var $parent = $that.parent();
      var id = $parent.data('id');
      var delete_url = $that.data('url');
      delete_url = delete_url.replace('id', id);
      $.post(delete_url, function () {
        console.log($parent);
        $parent.remove();
      });
    })
    $('.calendar-lesson.label-warning').append($delete);
  },
  truncate: function (str, length, ending) {
    if (length == null) {
      length = 20;
    }
    if (ending == null) {
      ending = '...';
    }
    if (str.length > length) {
      return str.substring(0, length - ending.length) + ending;
    } else {
      return str;
    }
  },
  getTarget: function (date) {
    var id = date.format('#YY-MM-DD-HH-00');
    if (date.format('m') > 30) {
      id = date.format('#YY-MM-DD-HH-30');
    }
    var $target = $(id);
    if ($target.length) {
      return $target;
    }
    id = date.format('#YY-MM-DD');
    $target = $(id);
    return $target;
  },
  highlightToday: function () {
    var d = new Date();
    var year = ("0" + d.getFullYear()).slice(-2);
    var day = ("0" + d.getDate()).slice(-2);
    var month = ("0" + (d.getMonth() + 1)).slice(-2);
    var today = year + '-' + month + '-' + day;
    $('[id^=' + today + ']').each(function () {
      $(this).addClass('grey');
    });
  }
};