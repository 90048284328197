$(document).ready(function () {
    $('textarea').each(function(){
        $(this).attr('id','ckeditor');
    })
    if ($('.ckeditor').length)         // use this if you are using id to check
    {
        CKEDITOR.config.toolbar_MA=[
            [
                'Format',
                'Templates',
                'Bold',
                'Italic',
                'Strike',
                'Underline','-',
                ['JustifyLeft','JustifyCenter','JustifyRight','JustifyBlock'],'-',
                'NumberedList','BulletedList','-',
                'Outdent',
                'Indent',
                'TextColor',
                'BGColor' ]];
        CKEDITOR.replace('ckeditor', {
            extraPlugins: 'colorbutton',
            removePlugins: '',
            toolbar:'MA'
        });
    }
});